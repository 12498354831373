var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"py-0 px-0"},[_c('v-container',{staticClass:"px-0"},[_c('v-row',[_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"12"}},[_c('v-btn-toggle',{attrs:{"dark":"","mandatory":"","rounded":""},model:{value:(_setup.segment),callback:function ($$v) {_setup.segment=$$v},expression:"segment"}},_vm._l((_setup.segmentItems),function(e,i){return _c('v-btn',{key:i,attrs:{"color":_setup.segment === i ? 'green darken-2' : 'grey darken-1'}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_setup.segment === i),expression:"segment === i"}],attrs:{"left":""}},[_vm._v(" mdi-check ")]),_vm._v(" "+_vm._s(e.text)+" ")],1)}),1)],1)],1),(_setup.segment == 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"text-h5"},[_vm._v("退勤日")])]),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"8"}},[_c('div',[_c('v-btn-toggle',{attrs:{"dark":"","mandatory":"","rounded":""},model:{value:(_setup.modelValue.isNightShift),callback:function ($$v) {_vm.$set(_setup.modelValue, "isNightShift", $$v)},expression:"modelValue.isNightShift"}},[_c('v-btn',{attrs:{"value":"0","color":_setup.modelValue.isNightShift == 0
                    ? 'green darken-2'
                    : 'grey darken-1'}},[(_setup.modelValue.isNightShift == 0)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e(),_vm._v(" 当日 ")],1),_c('v-btn',{attrs:{"value":"1","color":_setup.modelValue.isNightShift == 1
                    ? 'green darken-2'
                    : 'grey darken-1'}},[(_setup.modelValue.isNightShift == 1)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e(),_vm._v(" 前日 ")],1)],1)],1)])],1):_vm._e(),(!_setup.segment)?_c('v-row',[_c('v-col',{staticClass:"px-0",staticStyle:{"text-align":"left"},attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"error--text text-h7 px-0"},[_vm._v(" "+_vm._s(_setup.Messages.OvertimeHint.EndTime)+" ")]),_c(_setup.TimePicker2,{attrs:{"label":"延長時間","prepend-icon":"mdi-clock-outline","hide-details":"auto","close-on-complete":"","hour-label":" ","minute-label":" ","minute-interval":15,"rules":_setup.modelValue.isNightShift == 0
                ? [_setup.InputRules.isNotEmpty, _setup.InputRules.time, _setup.overtimeRule]
                : []},model:{value:(_setup.modelValue.overtimeWorkEnd),callback:function ($$v) {_vm.$set(_setup.modelValue, "overtimeWorkEnd", $$v)},expression:"modelValue.overtimeWorkEnd"}})],1),_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"error--text text-h7 px-0"},[_vm._v(" "+_vm._s(_setup.Messages.OvertimeHint.BreakTime)+" ")]),_c(_setup.TimePicker2,{attrs:{"label":"休憩時間","prepend-icon":"mdi-clock-outline","hide-details":"auto","close-on-complete":"","hour-label":" ","minute-label":" ","minute-interval":15,"rules":[_setup.InputRules.isNotEmpty, _setup.InputRules.time, _setup.breakTimeRules]},model:{value:(_setup.modelValue.overtimeWorkBreak),callback:function ($$v) {_vm.$set(_setup.modelValue, "overtimeWorkBreak", $$v)},expression:"modelValue.overtimeWorkBreak"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{attrs:{"value":_setup.modelValue.endTimeStamp,"label":"打刻終了時間","filled":"","dense":"","hide-details":"","disabled":true}})],1)],1),(!_setup.segment)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"理由","items":_setup.reasonList,"filled":"","dense":"","hide-details":"auto","rules":[_setup.InputRules.required]},model:{value:(_setup.modelValue.reason),callback:function ($$v) {_vm.$set(_setup.modelValue, "reason", $$v)},expression:"modelValue.reason"}})],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }