import { Component } from "vue";

export class AttendanceAlertRequest {
  employeeId: number;
  affiliationId: number;

  constructor(props: AttendanceAlertRequest) {
    this.employeeId = props.employeeId;
    this.affiliationId = props.affiliationId;
  }
}

export type AlertSearchRequest = {
  dateYearMonth?: string;
  approvals?: string[];
};

// export type AlertApplicationRequest = {
//   employeeId: string;
//   affiliationId: string;
//   component: Component;
// };

export class AlertApplicationRequest {
  employeeId: string;
  affiliationId: string;
  applicationId?: number;

  constructor(
    employeeId: string,
    affiliationId: string,
    applicationId?: number
  ) {
    this.employeeId = employeeId;
    this.affiliationId = affiliationId;
    this.applicationId = applicationId;
  }
}
