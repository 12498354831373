
import Vue, { PropType } from "vue";

//出勤種別
const WorkType = {
  Default: "0",
  Holiday: "1"
} as const;
type WorkType = typeof WorkType[keyof typeof WorkType];

const workItems = {
  text: "出勤種別:",
  value: "work",
  radio: [
    { text: "通常", value: WorkType.Default },
    { text: "休日", value: WorkType.Holiday }
  ]
} as const;

//応援
const AssistType = {
  None: "0",
  Assist: "1"
} as const;
type AssistType = typeof AssistType[keyof typeof AssistType];

const assistItems = {
  text: "応援:",
  value: "assist",
  radio: [
    { text: "なし", value: AssistType.None },
    { text: "応援", value: AssistType.Assist }
  ]
} as const;

//延長
const OverWorkType = {
  No: "0",
  Yes: "1"
} as const;
type OverWorkType = typeof OverWorkType[keyof typeof OverWorkType];

const overWorkItems = {
  text: "延長:",
  value: "overWork",
  radio: [
    { text: "なし", value: OverWorkType.No },
    { text: "あり", value: OverWorkType.Yes }
  ]
} as const;

//退勤日フラグ
const ClockedOutType = {
  currentDay: "0",
  prevDay: "1"
} as const;
type ClockedOutType = typeof ClockedOutType[keyof typeof ClockedOutType];

const clockedOutTypeItems = {
  text: "退勤:",
  value: "nightShift",
  radio: [
    { text: "当日", value: ClockedOutType.currentDay },
    { text: "前日", value: ClockedOutType.prevDay }
  ]
} as const;

const items = [workItems, overWorkItems, clockedOutTypeItems] as const;

export class AttendanceFixRadio {
  work: WorkType = WorkType.Default;
  assist: AssistType = AssistType.None;
  overWork: OverWorkType = OverWorkType.No;
  nightShift: ClockedOutType = ClockedOutType.currentDay;

  constructor(props?: {
    work: WorkType;
    assist: AssistType;
    overWork: OverWorkType;
    nightShift: ClockedOutType;
  }) {
    console.log("props", props);
    if (props) {
      if (props.work) this.work = props.work;
      if (props.assist) this.assist = props.assist;
      if (props.overWork) this.overWork = props.overWork;
      if (props.nightShift) this.nightShift = props.nightShift;
    }
  }
}

export default Vue.extend({
  name: "AttendanceFixRadioForm",
  props: {
    value: {
      type: Object as PropType<AttendanceFixRadio>,
      required: true
    }
  },
  data() {
    return {
      items,
      minuteInterval: 15
    };
  },
  computed: {
    modelValue: {
      get(): AttendanceFixRadio {
        return this.value;
      },
      set(newValue: AttendanceFixRadio) {
        this.$emit("input", newValue);
      }
    }
  }
});
