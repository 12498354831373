<template>
  <DialogBase
    visible
    ref="dialog"
    title="ログインしてください。"
    icon="mdi-login"
    small
  >
    <v-form v-model="valid" ref="form" @submit.prevent="login">
      <div class="text-center">
        <p class="text-h5">QRコードをスキャン</p>
        <v-icon size="200" @click.stop="readQr">mdi-qrcode-scan</v-icon>
        <v-divider class="my-5"></v-divider>

        <!--
        <div class="text-center">
          <p class="text-h5">またはログイン</p>

          <v-text-field
            v-model="id"
            label="ログインID"
            prepend-inner-icon="mdi-account"
            outlined
            rounded
            dense
            :rules="[Rules.Required]"
          ></v-text-field>

          <v-text-field
            v-model="password"
            label="パスワード"
            prepend-inner-icon="mdi-lock"
            :append-icon="!eye ? 'mdi-eye' : 'mdi-eye-off'"
            :type="eye ? 'text' : 'password'"
            outlined
            rounded
            dense
            @click:append="eye = !eye"
            :rules="[Rules.Required]"
          ></v-text-field>
        </div>
      --></div>
    </v-form>

    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="$close(false)">
        閉じる
      </v-btn>
      <!-- <v-btn color="primary" text @click="login">
        ログイン
      </v-btn> -->
    </template>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </DialogBase>
</template>

<script>
// lang="ts"
import Dialog from "@/mixins/dialog";
import Api from "@/mixins/api";
import Form from "@/mixins/forms";

import ShowDialogs from "@/mixins/showDialogs";

export default {
  name: "TemporaryLogin",
  mixins: [Dialog, Api, ShowDialogs, Form],
  props: {},
  data() {
    return {
      id: "",
      password: "",
      eye: false,
      overlay: false
    };
  },
  methods: {
    async login() {
      if (!this.$refs.form.validate()) return;

      this.overlay = true;
      try {
        await new Promise(resolve => setTimeout(resolve, 1500)).finally(() => {
          this.$close(false);
        });

        const payload = {
          loginId: this.id,
          password: this.password,
          isQr: "0"
        };

        const resp = await this.$tmpauth(payload);
        console.log("login tmpauth");
        this.$store.commit("user/login", resp);
        this.$close(true);
      } catch (e) {
        console.error(e);
        this.$error(e.message);
      } finally {
        this.overlay = false;
      }
    },
    async readQr() {
      this.overlay = true;
      try {
        await new Promise(resolve => setTimeout(resolve, 1500));

        const result = await this.$ReadQR(); //await ReadQR(this);
        console.log("qrResult", result);

        if (result != undefined) {
          const split = result.split("@");

          const data = {
            loginId: split[0],
            password: split[1],
            isQr: "1"
          };
          console.log("before tmpauth");
          const resp = await this.$tmpauth(data);
          console.log(resp);

          this.$store.commit("user/login", resp);
          this.$close(true);
        }

        this.$close(false);
        // await this.login(true);
      } catch (e) {
        console.error(e);
        this.$error(e.message);
        this.$store.commit("logout");
        this.$store.commit("user/logout");
        this.$router.push({ name: "Login" });
      } finally {
        this.overlay = false;
      }
    }
  }
};
</script>

<style></style>
