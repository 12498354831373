import { render, staticRenderFns } from "./AlertView.vue?vue&type=template&id=52844ad0&scoped=true"
import script from "./AlertView.vue?vue&type=script&setup=true&lang=ts"
export * from "./AlertView.vue?vue&type=script&setup=true&lang=ts"
import style1 from "./AlertView.vue?vue&type=style&index=1&id=52844ad0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52844ad0",
  null
  
)

export default component.exports